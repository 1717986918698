const localhost = "http://192.168.31.51";
const port = "3000";

const cloud_url = "https://data.jyanganj.com";

const baseUrl = `${cloud_url}`;

// const baseUrl = `${localhost}:${port}`;

// Export the base URL
export default baseUrl;
