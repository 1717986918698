import React, { useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import baseUrl from "./Configs";
import "./Css/ShowRemarks.css";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
} from "./Atoms";
import io from "socket.io-client";
import { Box, TextField, IconButton, Paper, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

// Ensure socket is initialized only once
const socket = io(baseUrl, {
  transports: ["websocket"],
});

const ShowRemarks = ({ studentId }) => {
  const [selectedClass] = useRecoilState(teacherSelectedClassState);
  const [selectedSection] = useRecoilState(teacherSelectedSectionState);
  const [remarks, setRemarks] = useState([]);
  const [newRemark, setNewRemark] = useState("");
  const currentUserId = localStorage.getItem("userId"); // Logged-in user's ID
  const chatEndRef = useRef(null); // Ref for auto-scrolling

  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getRemarksUrl = `${baseUrl}/v1/studentroute/getgeneralremarks?studentId=${studentId}`;
  const addRemarksUrl = `${baseUrl}/v1/studentroute/addgeneralremark`;

  // Fetch all remarks initially
  const getRemarks = async () => {
    try {
      const response = await fetch(getRemarksUrl, options);
      const data = await response.json();
      setRemarks(data);
    } catch (error) {
      console.error("Error fetching remarks:", error);
    }
  };

  // Add a new remark
  const addRemark = async () => {
    if (!newRemark.trim()) return;

    const remarkData = {
      studentId: studentId,
      remark: newRemark,
    };

    try {
      const response = await fetch(addRemarksUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(remarkData),
      });

      if (response.ok) {
        setNewRemark("");
      } else {
        console.error("Error adding remark:", response.statusText);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    getRemarks();

    socket.on("connect", () => console.log("Socket connected:", socket.id));
    socket.on("connect_error", (err) => console.error("Socket error:", err));

    // Listen for new remarks
    const handleNewRemark = (newRemarkData) => {
      if (newRemarkData.studentId === studentId) {
        setRemarks((prev) => [...prev, newRemarkData]);
      }
    };

    socket.on("newRemark", handleNewRemark);

    return () => {
      socket.off("newRemark", handleNewRemark);
    };
  }, [studentId]);

  // Auto-scroll to last message
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [remarks]);

  return (
    <Box className="table-component chat-container">
      {/* Chat Messages */}
      <Box className="chat-box">
        {remarks.length > 0 ? (
          remarks.map((remark) => (
            <Paper
              key={remark._id}
              className={`chat-bubble ${
                remark.author?._id === currentUserId ? "sent" : "received"
              }`}
            >
              <Typography variant="body1">{remark.remark}</Typography>
              <Typography variant="caption" className="remark-meta">
                {remark.author?.personname || "Unknown"} •{" "}
                {new Date(remark.timestamp).toLocaleTimeString()}
              </Typography>
            </Paper>
          ))
        ) : (
          <Typography variant="body2" className="no-remarks">
            No remarks yet.
          </Typography>
        )}
        {/* Empty div to maintain scroll position */}
        <div ref={chatEndRef}></div>
      </Box>

      {/* Input Field */}
      <Box className="chat-input">
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Type a message..."
          value={newRemark}
          onChange={(e) => setNewRemark(e.target.value)}
        />
        <IconButton color="primary" onClick={addRemark}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ShowRemarks;
