import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import axios from "axios";
import baseUrl from "./Configs";
import Header from "./Header";
import { useSetRecoilState, useRecoilState } from "recoil";
import { headerSelectorsState } from "./Atoms";

const SalaryStatus = () => {
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [currentSalary, setCurrentSalary] = useState("");
  const [newSalary, setNewSalary] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [salaryHistory, setSalaryHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/v1/staffroute/getallstaff`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setStaff(response.data);
      } catch (error) {
        console.error("Error fetching staff:", error);
        setStaff([]); // Ensure staff is set to an empty array on error
      }
    };

    fetchStaff();
  }, []);

  const handleStaffChange = (event) => {
    const staffId = event.target.value;
    setSelectedStaff(staffId);

    // Fetch current salary and history for the selected staff
    axios
      .get(`${baseUrl}/v1/salaryroute/getsalary?id=${staffId}`, optionsForGet)
      .then((response) => {
        const salaryData = response.data;
        setCurrentSalary(salaryData.baseSalaryPerMonth); // Set base salary
        setSalaryHistory(salaryData.paymentHistory); // Set payment history
      })
      .catch((error) => console.error("Error fetching salary:", error));
  };

  const handleSetSalary = async () => {
    try {
      await axios.post(
        `${baseUrl}/v1/salaryroute/setsalary`,
        {
          staffId: selectedStaff,
          baseSalaryPerMonth: newSalary,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setCurrentSalary(newSalary); // Update current salary
      setNewSalary("");
      setErrorMessage(""); // Clear any previous errors
      handleCloseDialog(); // Close the dialog on success
    } catch (error) {
      console.error("Error setting salary:", error);
      setErrorMessage(
        error.response?.data?.message ||
          "Failed to set salary. Please try again."
      );
    }
  };

  const handlePaySalary = () => {
    // Pay salary to the selected staff
    axios
      .post(
        `${baseUrl}/v1/salaryroute/paysalary`,
        {
          staffId: selectedStaff,
          amountPaid: paymentAmount,
          transactionId,
          paymentMode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        // Update salary history after payment
        setSalaryHistory([
          ...salaryHistory,
          {
            amountPaid: paymentAmount,
            transactionId,
            paymentMode,
            date: new Date().toLocaleDateString(),
          },
        ]);
        setPaymentAmount("");
        setTransactionId("");
        setPaymentMode("");
      })
      .catch((error) => console.error("Error paying salary:", error));
  };

  useEffect(() => {
    setHeaderSelectors({
      classSelector: false,
      sectionSelector: false,
      subjectSelector: false,
      dateSelector: false,
      backMethod: null,
    });
  }, []);

  return (
    <div>
      <Header
        selectors={{
          classSelector: false,
          sectionSelector: false,
          subjectSelector: false,
          dateSelector: false,
        }}
      />
      <Container>
        <Typography variant="h4" gutterBottom>
          Salary Management
        </Typography>
        <TextField
          select
          label="Select Staff"
          value={selectedStaff}
          onChange={handleStaffChange}
          fullWidth
          margin="normal"
        >
          {staff.length > 0 ? (
            staff.map((member) => (
              <MenuItem key={member.id} value={member.id}>
                {member.name} - {member.role}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>Loading staff...</MenuItem>
          )}
        </TextField>
        {selectedStaff && (
          <>
            <Typography variant="h6" gutterBottom>
              Current Salary: ₹{currentSalary}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    fontSize: "18px",
                    // padding: "20px",
                    width: "100%",
                    height: "100%",
                    margin: "20px 0",
                    textAlign: "center",
                  }}
                  onClick={handleOpenDialog}
                >
                  Update Salary
                </Button>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle>Set New Salary</DialogTitle>
                  <DialogContent>
                    <TextField
                      label="New Salary"
                      value={newSalary}
                      onChange={(e) => setNewSalary(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    {errorMessage && (
                      <Typography color="error" style={{ marginTop: "10px" }}>
                        {errorMessage}
                      </Typography>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                      Cancel
                    </Button>
                    <Button onClick={handleSetSalary} color="primary">
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Payment Amount"
                  value={paymentAmount}
                  onChange={(e) => setPaymentAmount(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Transaction ID"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  select
                  label="Payment Mode"
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  fullWidth
                  margin="normal"
                >
                  {[
                    "Cash",
                    "Bank Transfer",
                    "Cheque",
                    "Online Payment",
                    "UPI",
                    "Other",
                  ].map((mode) => (
                    <MenuItem key={mode} value={mode}>
                      {mode}
                    </MenuItem>
                  ))}
                </TextField>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePaySalary}
                  fullWidth
                >
                  Pay Salary
                </Button>
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
              Salary Payment History
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount Paid (₹)</TableCell>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Payment Mode</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salaryHistory &&
                    salaryHistory.map((record, index) => (
                      <TableRow key={index}>
                        <TableCell>{record.date}</TableCell>
                        <TableCell>{record.amountPaid}</TableCell>
                        <TableCell>{record.transactionId}</TableCell>
                        <TableCell>{record.paymentMode}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </div>
  );
};

export default SalaryStatus;
