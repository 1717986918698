import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  receivedStudentListState,
  teacherSelectedExamState,
  teacherSelectedClassState,
  teacherSelectedSectionState,
  teacherSelectedDateState,
  teacherSelectedSubjectState,
} from "../Atoms";
import baseUrl from "../Configs";

import {
  Box,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";

const ExamRating = ({ onSubmit }) => {
  const [studentwiseScore, setStudentwiseScore] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility
  const [isLoading, setIsLoading] = useState(false);

  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedDate = useRecoilValue(teacherSelectedDateState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);
  // const receivedStudentList = useRecoilValue(receivedStudentListState);
  const teacherSelectedExam = useRecoilValue(teacherSelectedExamState);
  const [receivedStudentList, setReceivedStudentListState] = useRecoilState(
    receivedStudentListState
  );
  const teacherName = "Joshiya Nanda"; // replace with actual teacher name

  let addTestReportUrl = `${baseUrl}/v1/studentroute/updatetestreport`;

  useEffect(() => {
    // Initialize studentwiseScore with student roll numbers
    setStudentwiseScore(
      receivedStudentList.map((student) => ({
        rollNumber: student.rollNumber,
        report: {
          score: "",
          remark: "",
        },
      }))
    );
  }, [receivedStudentList]);

  const handleScoreChange = (index, score) => {
    const newScores = [...studentwiseScore];
    newScores[index].report.score = score;
    setStudentwiseScore(newScores);
  };

  const handleRemarkChange = (index, remark) => {
    const newScores = [...studentwiseScore];
    newScores[index].report.remark = remark;
    setStudentwiseScore(newScores);
  };

  const fetchStudents = async () => {
    // setIsLoading(true);
    const getStudentsUrl = `${baseUrl}/v1/classroute/getstudentlist?className=${teacherSelectedClass}&section=${teacherSelectedSection}`;
    const response = await fetch(getStudentsUrl, optionsForGet);
    const data = await response.json();
    setReceivedStudentListState(data);
    // setIsLoading(false);
  };

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if className, section, or subject is missing
    if (
      !teacherSelectedClass ||
      !teacherSelectedSection ||
      !teacherSelectedSubject
    ) {
      setOpenDialog(true); // Show dialog if required fields are missing
      return;
    }

    const payload = {
      className: teacherSelectedClass,
      section: teacherSelectedSection,
      testName: teacherSelectedExam,
      date: teacherSelectedDate,
      subject: teacherSelectedSubject,
      takenBy: teacherName,
      testReports: studentwiseScore,
    };

    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };

    fetch(addTestReportUrl, options)
      .then((data) => data.json())
      .then((jsonData) => console.log(jsonData))
      .then(() => onSubmit())
      .catch((e) => console.log(e));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };

  useEffect(() => {
    // if (receivedStudentList.length === 0) {
    fetchStudents();
    // }
  }, [teacherSelectedClass, teacherSelectedSection]);

  return (
    <Box sx={{ padding: 4 }}>
      {/* <Typography variant="h4" gutterBottom>
        Exam Rating
      </Typography> */}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {receivedStudentList.map((student, index) => (
            <Grid item xs={12} key={student.rollNumber}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography>{`${student.rollNumber} - ${student.name}`}</Typography>
                  <TextField
                    label="Score"
                    type="number"
                    variant="outlined"
                    value={studentwiseScore[index]?.report.score}
                    onChange={(e) => handleScoreChange(index, e.target.value)}
                    sx={{ marginRight: 2 }}
                  />
                  <TextField
                    label="Remark"
                    variant="outlined"
                    value={studentwiseScore[index]?.report.remark}
                    onChange={(e) => handleRemarkChange(index, e.target.value)}
                  />
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: 3 }}
        >
          Submit Scores
        </Button>
      </form>

      {/* Dialog for missing className, section, or subject */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Incomplete Information</DialogTitle>
        <DialogContent>
          <Typography>
            Please select the class, section, and subject before submitting the
            exam ratings.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExamRating;
