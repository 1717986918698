import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Grid,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import baseUrl from "./Configs";
import Header from "./Header";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
  selectedClassIdState,
  headerSelectorsState,
} from "./Atoms";
import { toCamelCase, toTitleCase } from "./Utils";
import { set } from "date-fns";

const CostManagement = () => {
  const [loading, setLoading] = useState(false);
  const [feeType, setFeeType] = useState("");
  const [newFeeTypeInput, setNewFeeTypeInput] = useState(""); // For new fee type entry
  const [amount, setAmount] = useState("");
  const [defaultAmount, setDefaultAmount] = useState("");
  const [feeFrequency, setFeeFrequency] = useState("monthly"); // Default to monthly
  const [description, setDescription] = useState("");
  // monthIndex = "all" means global (default) fee; otherwise a specific month is selected.
  const [monthIndex, setMonthIndex] = useState("all");
  const [classFeeDetails, setClassFeeDetails] = useState([]); // Fetched fee details of class
  const [isExistingFeeType, setIsExistingFeeType] = useState(true); // Tracks whether fee type is from existing list
  const [partialStudentList, setPartialStudentList] = useState([]);

  const [showIndividualFeeSection, setShowIndividualFeeSection] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [studentFeeDetails, setStudentFeeDetails] = useState({});
  const [studentFormData, setStudentFormData] = useState({});

  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const selectedClassId = useRecoilValue(selectedClassIdState);
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  // API endpoint to update fee details for class
  const setFeeForClassUrl = `${baseUrl}/v1/feeroute/setfeeforclass`;
  // API endpoint to fetch existing class fee details
  const getLastFeeDetailsOfClassUrl = `${baseUrl}/v1/feeroute/getfeedetailsofclass?classId=${selectedClassId}`;
  const getPartialStudentListUrl = `${baseUrl}/v1/classroute/getpartialstudentlist?classId=${selectedClassId}`;
  const updateRequiredFeeForStudentUrl = `${baseUrl}/v1/feeroute/updaterequiredfeeforstudent`;
  const optionsForGet = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  // Fetch existing fee details for the class
  const fetchClassFeeDetails = async () => {
    try {
      const response = await fetch(getLastFeeDetailsOfClassUrl, optionsForGet);
      const data = await response.json();
      if (data.status === 200 && data.feeDetails) {
        setClassFeeDetails(data.feeDetails);
      } else {
        setClassFeeDetails([]);
      }
    } catch (error) {
      console.error("Error fetching class fee details:", error);
    }
  };

  useEffect(() => {
    if (selectedClassId) {
      fetchClassFeeDetails();
      getPartialStudentList();
    }
  }, [selectedClassId]);

  const getPartialStudentList = async () => {
    try {
      const response = await fetch(getPartialStudentListUrl, optionsForGet);
      const data = await response.json();
      setPartialStudentList(data.studentList);
    } catch (error) {
      console.error("Error fetching partial student list:", error);
    }
  };

  // When feeType is changed, prepopulate form if it exists
  const handleFeeTypeChange = (event) => {
    const selected = event.target.value;
    setFeeType(selected);
    if (selected === "new") {
      // "New Fee Type" option selected
      setIsExistingFeeType(false);
      // Clear form fields for new entry
      setAmount("");
      setDefaultAmount("");
      setFeeFrequency("monthly");
      setDescription("");
    } else {
      setIsExistingFeeType(true);
      // Find the fee detail for the selected fee type from classFeeDetails
      const feeDetail = classFeeDetails.find((f) => f.feeType === selected);
      if (feeDetail) {
        setAmount(feeDetail.defaultAmount);
        setDefaultAmount(feeDetail.defaultAmount);
        setFeeFrequency(feeDetail.feeFrequency);
        setDescription(feeDetail.description);
        // For simplicity, we leave monthIndex as "all" by default.
        setMonthIndex("all");
      }
    }
  };

  // Handle new fee type input change
  const handleNewFeeTypeInputChange = (e) => {
    setNewFeeTypeInput(e.target.value);
  };

  // Handle amount, default amount, description changes
  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleDefaultAmountChange = (e) => setDefaultAmount(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleFeeFrequencyChange = (e) => setFeeFrequency(e.target.value);

  // Month selection change; "all" option excludes month from API payload.
  const handleMonthIndexChange = (e) => {
    setMonthIndex(e.target.value);
  };

  // Submit handler: update or add fee details for class.
  const handleSetFee = async () => {
    // Check required fields: if "all" is selected, defaultAmount is required; otherwise, amount is required.
    if (!isExistingFeeType && !newFeeTypeInput.trim()) {
      alert("Please enter a fee type (required).");
      return;
    }
    if (feeType === "") {
      alert("Please select a fee type.");
      return;
    }
    if (!feeFrequency) {
      alert("Please fill all required fields marked with *.");
      return;
    }
    if (monthIndex === "all" && !defaultAmount) {
      alert("Default Amount is required when 'All' is selected for month.");
      return;
    }
    if (monthIndex !== "all" && !amount) {
      alert("Amount is required when a specific month is selected.");
      return;
    }

    if (selectedClassId === "") {
      alert("Please select a class.");
      return;
    }

    // If a new fee type is entered, convert it to camelCase and use it.
    let finalFeeType = feeType;
    if (!isExistingFeeType) {
      finalFeeType = toCamelCase(newFeeTypeInput.trim());
    }

    // Construct payload; if monthIndex is "all", do not include month.
    const payload = {
      classId: selectedClassId,
      feeData: {
        feeType: finalFeeType,
        amount: monthIndex === "all" ? defaultAmount : amount,
        feeFrequency,
        description,
        defaultStartDate: new Date(), // or use a specific date if needed
        defaultAmount: defaultAmount || amount,
        month:
          feeFrequency === "monthly" && monthIndex !== "all"
            ? new Date(new Date().getFullYear(), Number(monthIndex), 1)
            : null,
      },
    };

    try {
      setLoading(true);
      const response = await fetch(setFeeForClassUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      setLoading(false);
      // console.log("Fee update response:", data);
      alert(data.message || "Fee details updated successfully.");
      // Optionally, clear the form after success.
      setFeeType("");
      setNewFeeTypeInput("");
      setAmount("");
      setDefaultAmount("");
      setFeeFrequency("monthly");
      setDescription("");
      setMonthIndex("all");
      // Refresh class fee details after update.
      fetchClassFeeDetails();
    } catch (error) {
      console.error("Error updating fee:", error);
      alert("Error updating fee details.");
      setLoading(false);
    }
  };

  const handleStudentFormChange = (studentId, field, value) => {
    setStudentFormData((prev) => ({
      ...prev,
      [studentId]: {
        ...(prev[studentId] || {}),
        [field]: value,
      },
    }));
  };
  const handleMonthChange = (studentId, date) => {
    const month = date
      ? new Date(date).toISOString().split("T")[0].substring(0, 7) + "-01"
      : "";
    handleStudentFormChange(studentId, "month", month);
  };

  const handleFeeTypeSelect = (studentId, feeType) => {
    const classFee = classFeeDetails.find((f) => f.feeType === feeType);
    const studentFee = (studentFeeDetails[studentId] || []).find(
      (f) => f.feeType === feeType
    );

    handleStudentFormChange(studentId, "feeType", feeType);
    handleStudentFormChange(
      studentId,
      "feeFrequency",
      studentFee?.feeFrequency || classFee?.feeFrequency || "monthly"
    );
    handleStudentFormChange(
      studentId,
      "description",
      studentFee?.description || classFee?.description || ""
    );

    // Check if monthly fee exists for selected month
    if (studentFee && studentFormData[studentId]?.month) {
      const monthlyFee = studentFee.monthlyFeeDetails.find((m) =>
        new Date(m.month)
          .toISOString()
          .startsWith(studentFormData[studentId].month)
      );
      if (monthlyFee) {
        handleStudentFormChange(studentId, "amount", monthlyFee.amount);
      }
    }
  };

  // Add this function for fetching student fee details
  const fetchStudentFeeDetails = async (studentId) => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/feeroute/getstudentfeestatus?sti=${studentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      const data = await response.json();
      if (data.feeDetails) {
        setStudentFeeDetails((prev) => ({
          ...prev,
          [studentId]: data.feeDetails,
        }));
      }
    } catch (error) {
      console.error("Error fetching student fee details:", error);
    }
  };

  // Add this submit handler
  const handleStudentFeeSubmit = async (studentId) => {
    const formData = studentFormData[studentId] || {};
    const isAllMonths = formData.monthIndex === "all";

    if (
      !formData.feeType ||
      !formData.feeFrequency ||
      (isAllMonths && !formData.defaultAmount) ||
      (!isAllMonths && !formData.amount)
    ) {
      alert("Please fill all required fields");
      return;
    }

    try {
      const payload = {
        studentId,
        feeType: formData.feeType,
        amount: isAllMonths ? formData.defaultAmount : formData.amount,
        feeFrequency: formData.feeFrequency,
        description: formData.description || "",
        month: isAllMonths
          ? null
          : new Date(new Date().getFullYear(), Number(formData.monthIndex), 1),
      };
      setLoading(true);
      const response = await fetch(updateRequiredFeeForStudentUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.status === 200) {
        alert("Fee updated successfully");
        fetchStudentFeeDetails(studentId); // Refresh student data
      } else {
        alert(data.message || "Error updating fee");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error updating student fee:", error);
      alert("Error updating fee");
      setLoading(false);
    }
  };

  useEffect(() => {
    setHeaderSelectors({
      classSelector: true,
      sectionSelector: true,
      subjectSelector: false,
      dateSelector: false,
      backMethod: null,
    });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <Header
          selectors={{
            classSelector: true,
            sectionSelector: true,
            subjectSelector: false,
          }}
        />

        <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
          <Typography variant="h5" gutterBottom>
            Set Class Fee
          </Typography>

          <Grid container spacing={2}>
            {/* Month Selection */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="month-select-label">Select Month *</InputLabel>
                <Select
                  labelId="month-select-label"
                  value={monthIndex}
                  onChange={handleMonthIndexChange}
                >
                  <MenuItem value="all">All</MenuItem>
                  {[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ].map((month, index) => (
                    <MenuItem key={index} value={index}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Fee Type Selection */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="fee-type-label">Select Fee Type *</InputLabel>
                <Select
                  labelId="fee-type-label"
                  value={feeType || (isExistingFeeType ? "" : "new")}
                  onChange={handleFeeTypeChange}
                >
                  {/* Option for new fee type */}
                  <MenuItem value="new">New Fee Type</MenuItem>
                  {/* Existing fee types from classFeeDetails, displayed in title case */}
                  {classFeeDetails &&
                    classFeeDetails.map((fee, idx) => (
                      <MenuItem key={idx} value={fee.feeType}>
                        {toTitleCase(fee.feeType)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* If "New Fee Type" is selected, show an input to enter it */}
          {!isExistingFeeType && (
            <Box mt={2}>
              <TextField
                fullWidth
                label="Enter New Fee Type *"
                value={newFeeTypeInput}
                onChange={handleNewFeeTypeInputChange}
                helperText="Will be converted to camelCase"
              />
            </Box>
          )}

          {/* Conditional Amount / Default Amount Field */}
          {monthIndex === "all" ? (
            <Box mt={2}>
              <TextField
                fullWidth
                label="Default Amount *"
                type="number"
                value={defaultAmount}
                onChange={handleDefaultAmountChange}
                helperText="This field is required when 'All' is selected."
              />
            </Box>
          ) : (
            <Box mt={2}>
              <TextField
                fullWidth
                label="Amount *"
                type="number"
                value={amount}
                onChange={handleAmountChange}
                helperText="Enter the amount for the selected month."
              />
            </Box>
          )}

          {/* Fee Frequency and Description */}
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="fee-frequency-label">
                  Fee Frequency *
                </InputLabel>
                <Select
                  labelId="fee-frequency-label"
                  value={feeFrequency}
                  onChange={handleFeeFrequencyChange}
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="one-time">One-Time</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={handleDescriptionChange}
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSetFee}
            >
              {loading ? <CircularProgress /> : <p>SET/UPDATE FEE</p>}
            </Button>
          </Box>
        </Paper>

        <Box mt={4}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() =>
              setShowIndividualFeeSection(!showIndividualFeeSection)
            }
            sx={{ mb: 2 }}
          >
            {showIndividualFeeSection ? "Hide" : "Show"} Individual Student Fee
            Management
          </Button>
          {showIndividualFeeSection && (
            <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
              <Typography variant="h5" gutterBottom>
                Individual Student Fee Management
              </Typography>

              <TextField
                fullWidth
                label="Search Students"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ mb: 2 }}
              />

              {partialStudentList
                .filter((student) =>
                  `${student.name}`
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
                .map((student) => {
                  const studentData = studentFormData[student.id] || {};
                  const isAllMonths = studentData.monthIndex === "all";

                  return (
                    <Accordion
                      key={student.id}
                      onChange={(e, expanded) =>
                        expanded && fetchStudentFeeDetails(student.id)
                      }
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                          {student.rollNumber}. {student.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {/* Month Selection */}
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Select Month *</InputLabel>
                              <Select
                                value={studentData.monthIndex || "all"}
                                onChange={(e) =>
                                  handleStudentFormChange(
                                    student.id,
                                    "monthIndex",
                                    e.target.value
                                  )
                                }
                              >
                                <MenuItem value="all">All</MenuItem>
                                {[
                                  "January",
                                  "February",
                                  "March",
                                  "April",
                                  "May",
                                  "June",
                                  "July",
                                  "August",
                                  "September",
                                  "October",
                                  "November",
                                  "December",
                                ].map((month, index) => (
                                  <MenuItem key={index} value={index}>
                                    {month}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          {/* Fee Type Selection */}
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Fee Type *</InputLabel>
                              <Select
                                value={studentData.feeType || ""}
                                onChange={(e) =>
                                  handleFeeTypeSelect(
                                    student.id,
                                    e.target.value
                                  )
                                }
                              >
                                {classFeeDetails.map((fee, index) => (
                                  <MenuItem key={index} value={fee.feeType}>
                                    {toTitleCase(fee.feeType)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          {/* Amount Fields */}
                          {isAllMonths ? (
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Default Amount *"
                                type="number"
                                value={studentData.defaultAmount || ""}
                                onChange={(e) =>
                                  handleStudentFormChange(
                                    student.id,
                                    "defaultAmount",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Amount *"
                                type="number"
                                value={studentData.amount || ""}
                                onChange={(e) =>
                                  handleStudentFormChange(
                                    student.id,
                                    "amount",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                          )}

                          {/* Fee Frequency and Description */}
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Fee Frequency *</InputLabel>
                              <Select
                                value={studentData.feeFrequency || "monthly"}
                                onChange={(e) =>
                                  handleStudentFormChange(
                                    student.id,
                                    "feeFrequency",
                                    e.target.value
                                  )
                                }
                              >
                                <MenuItem value="monthly">Monthly</MenuItem>
                                <MenuItem value="one-time">One-Time</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Description"
                              value={studentData.description || ""}
                              onChange={(e) =>
                                handleStudentFormChange(
                                  student.id,
                                  "description",
                                  e.target.value
                                )
                              }
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={() => handleStudentFeeSubmit(student.id)}
                            >
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                <div>Update Fee</div>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Paper>
          )}
        </Box>
      </div>
    </LocalizationProvider>
  );
};

export default CostManagement;
