import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import baseUrl from "./Configs";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
});

const RegisterForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const registerUrl = `${baseUrl}/v1/schoolroute/addnewschool`;

  const [schoolName, setSchoolName] = useState("");
  const [schoolAffiliationNumber, setSchoolAffiliationNumber] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const registerSchool = async (event) => {
    event.preventDefault();

    const response = await fetch(registerUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        schoolName,
        schoolAffiliationNumber,
        username,
        password,
      }),
    });

    if (response.ok) {
      const data = await response;
      console.log(data);
      navigate("/thankyou"); // Redirect to ThankYouPage
    } else {
      console.error("Failed to register school");
    }
  };

  return (
    <div className="register-form">
      <Typography variant="h4">Register</Typography>
      <form className={classes.form} onSubmit={registerSchool}>
        <TextField
          label="School Name"
          variant="outlined"
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
        />
        <TextField
          label="School Affiliation Number"
          variant="outlined"
          value={schoolAffiliationNumber}
          onChange={(e) => setSchoolAffiliationNumber(e.target.value)}
        />
        <TextField
          label="Email/Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">
          Register
        </Button>
      </form>
    </div>
  );
};

export default RegisterForm;
