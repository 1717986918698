import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
Chart.register(...registerables);

const TestReportChart = ({
  data,
  selectedTest,
  setSelectedTest,
  testReport,
}) => {
  console.log("data: ", data);
  const labels = data.report.map((report) => report.subject);
  const scores = data.report.map((report) => report.report.score);
  const remarks = data.report.map((report) => report.report.remark);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Scores",
        data: scores,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          afterBody: function (item) {
            return "Remark: " + remarks[item[0].dataIndex];
          },
        },
      },
    },
  };

  return (
    <div className="table-component">
      <FormControl>
        <InputLabel id="test-select-label">Select Test</InputLabel>
        <Select
          labelId="test-select-label"
          id="test-select"
          value={selectedTest}
          onChange={(e) => setSelectedTest(e.target.value)}
        >
          {testReport.map((report, index) => (
            <MenuItem key={index} value={report.testName}>
              {report.testName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <p>{data.testName}</p>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TestReportChart;
