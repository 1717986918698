import React, { useState } from "react";
import baseUrl from "./Configs";
import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Grid,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Import DeleteIcon

const AddNewStaff = () => {
  const [staffName, setStaffName] = useState("");
  const [staffId, setStaffId] = useState("");
  const [staffEmail, setStaffEmail] = useState("");
  const [staffPhoneNumber, setStaffPhoneNumber] = useState("");
  const [staffAddress, setStaffAddress] = useState("");
  const [staffGender, setStaffGender] = useState("");
  const [staffDateOfBirth, setStaffDateOfBirth] = useState("");
  const [staffRole, setStaffRole] = useState("");
  const [password, setPassword] = useState("");

  const [educationDetails, setEducationDetails] = useState([
    { institution: "", qualification: "", yearOfPassing: "" },
  ]);
  const [bankAccountDetails, setBankAccountDetails] = useState({
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    upiId: "",
  });

  const addNewStaffUrl = `${baseUrl}/v1/staffroute/addnewstaff`;

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate required fields
    if (
      !staffName.trim() ||
      !staffId.trim() ||
      !staffEmail.trim() ||
      !staffPhoneNumber.trim() ||
      !staffGender.trim() ||
      !staffDateOfBirth.trim() ||
      !staffRole.trim() ||
      !password.trim()
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    const staffInfo = {
      name: staffName,
      staffId: staffId,
      email: staffEmail,
      phoneNumber: staffPhoneNumber,
      address: staffAddress,
      gender: staffGender,
      dateOfBirth: staffDateOfBirth,
      role: staffRole,
      username: staffEmail,
      password: password,
      educationDetails: educationDetails,
      bankAccountDetails: bankAccountDetails,
    };

    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(staffInfo),
    };

    try {
      const response = await fetch(addNewStaffUrl, options);
      const data = await response.json();
      console.log(data);
      alert("Staff added successfully!");
    } catch (error) {
      console.error("Error adding new staff:", error);
      alert("Failed to add staff. Please try again.");
    }
  };

  const addEducationDetail = () => {
    setEducationDetails([
      ...educationDetails,
      { institution: "", qualification: "", yearOfPassing: "" },
    ]);
  };

  const handleEducationChange = (index, event) => {
    const values = [...educationDetails];
    values[index][event.target.name] = event.target.value;
    setEducationDetails(values);
  };

  const handleBankAccountChange = (event) => {
    setBankAccountDetails({
      ...bankAccountDetails,
      [event.target.name]: event.target.value,
    });
  };

  const deleteEducationDetail = (index) => {
    const updatedEducationDetails = educationDetails.filter(
      (_, i) => i !== index
    );
    setEducationDetails(updatedEducationDetails);
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Add New Staff
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Personal Details Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Personal Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <TextField
              label="Name"
              value={staffName}
              onChange={(e) => setStaffName(e.target.value)}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                labelId="gender-label"
                value={staffGender}
                onChange={(e) => setStaffGender(e.target.value)}
                required
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Date of Birth"
              type="date"
              value={staffDateOfBirth}
              onChange={(e) => setStaffDateOfBirth(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="Staff ID"
              value={staffId}
              onChange={(e) => setStaffId(e.target.value)}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="Phone Number"
              value={staffPhoneNumber}
              onChange={(e) => setStaffPhoneNumber(e.target.value)}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="Address"
              value={staffAddress}
              onChange={(e) => setStaffAddress(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>

          {/* Role and Bank Details Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Role and Bank Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                value={staffRole}
                onChange={(e) => setStaffRole(e.target.value)}
                required
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="teacher">Teacher</MenuItem>
                <MenuItem value="clerk">Clerk</MenuItem>
                <MenuItem value="librarian">Librarian</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Account Number"
              name="accountNumber"
              value={bankAccountDetails.accountNumber}
              onChange={handleBankAccountChange}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="Bank Name"
              name="bankName"
              value={bankAccountDetails.bankName}
              onChange={handleBankAccountChange}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="IFSC Code"
              name="ifscCode"
              value={bankAccountDetails.ifscCode}
              onChange={handleBankAccountChange}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="UPI ID"
              name="upiId"
              value={bankAccountDetails.upiId}
              onChange={handleBankAccountChange}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="Email/Username"
              value={staffEmail}
              onChange={(e) => setStaffEmail(e.target.value)}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>

        {/* Education Details Section */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Education Details
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {educationDetails.map((educationDetail, index) => (
            <Box
              key={index}
              sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}
            >
              <TextField
                label="Institution"
                name="institution"
                value={educationDetail.institution}
                onChange={(event) => handleEducationChange(index, event)}
                fullWidth
                required
              />
              <TextField
                label="Qualification"
                name="qualification"
                value={educationDetail.qualification}
                onChange={(event) => handleEducationChange(index, event)}
                fullWidth
                required
              />
              <TextField
                label="Year of Passing"
                name="yearOfPassing"
                value={educationDetail.yearOfPassing}
                onChange={(event) => handleEducationChange(index, event)}
                fullWidth
                required
              />
              <IconButton
                color="error"
                onClick={() => deleteEducationDetail(index)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            variant="outlined"
            color="primary"
            onClick={addEducationDetail}
          >
            Add Another Education Detail
          </Button>
        </Box>

        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Add Staff
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddNewStaff;
