import React, { useState, useEffect } from "react";
import baseUrl from "./Configs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useRecoilValue, useRecoilState } from "recoil";
import { receivedClassListState, headerSelectorsState } from "./Atoms";
import Header from "./Header";
import { toTitleCase } from "./Utils";

const feeTypes = ["tuitionFee", "transportFee", "libraryFee", "sportsFee"]; // Add actual fee types
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ClasswiseStatus = () => {
  const receivedClassList = useRecoilValue(receivedClassListState);
  const [feeType, setFeeType] = useState(feeTypes[0]);
  const [monthIndex, setMonthIndex] = useState(new Date().getMonth()); // Default: Current Month
  const [classwiseData, setClasswiseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  // Fetch data when feeType or month changes
  useEffect(() => {
    const fetchClasswiseFeeStatus = async () => {
      setLoading(true);
      try {
        const responses = await Promise.all(
          receivedClassList.map(async (classItem) => {
            const url = `${baseUrl}/v1/feeroute/getfeestatusofclass?monthIndex=${monthIndex}&classId=${classItem.classId}&feeType=${feeType}`;
            const response = await fetch(url, optionsForGet);
            const data = await response.json();
            return { ...classItem, ...data };
          })
        );
        setClasswiseData(responses);
      } catch (error) {
        console.error("Error fetching classwise fee status:", error);
      }
      setLoading(false);
    };

    if (receivedClassList.length > 0) {
      fetchClasswiseFeeStatus();
    }
  }, [feeType, monthIndex, receivedClassList]);

  useEffect(() => {
    setHeaderSelectors({
      classSelector: false,
      sectionSelector: false,
      subjectSelector: false,
      dateSelector: false,
      backMethod: null,
    });
  }, []);

  return (
    <div>
      <Header
        selectors={{
          classSelector: false,
          sectionSelector: false,
          subjectSelector: false,
        }}
      />
      <div style={{ padding: "20px" }}>
        <h2>Classwise Fee Status</h2>

        {/* Dropdown Selectors */}
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Fee Type</InputLabel>
              <Select
                value={feeType}
                onChange={(e) => setFeeType(e.target.value)}
              >
                {feeTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {toTitleCase(type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Month</InputLabel>
              <Select
                value={monthIndex}
                onChange={(e) => setMonthIndex(e.target.value)}
              >
                {months.map((month, index) => (
                  <MenuItem key={month} value={index}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Data Table */}
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Class Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Section</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Expected Total Fee</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Collected Total Fee</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classwiseData.length > 0 ? (
                  classwiseData.map((classItem) => (
                    <TableRow key={classItem.classId}>
                      <TableCell>{classItem.className}</TableCell>
                      <TableCell>{classItem.section}</TableCell>
                      <TableCell>{classItem.expectedTotal || 0}</TableCell>
                      <TableCell>{classItem.collectedTotal || 0}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: "center" }}>
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default ClasswiseStatus;
