import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import {
  teacherSelectedClassState,
  teacherSelectedDateState,
  teacherSelectedSubjectState,
  teacherSelectedSectionState,
} from "../Atoms";
import baseUrl from "../Configs";

import {
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const AddHomework = ({ onSubmit }) => {
  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedDate = useRecoilValue(teacherSelectedDateState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);

  const [homeworkPoints, setHomeworkPoints] = useState([""]);
  const [openDialog, setOpenDialog] = useState(false); // State to control the dialog

  const addHomeworkUrl = `${baseUrl}/v1/classroute/addnewhomework`;

  const handleInputChange = (index, event) => {
    const values = [...homeworkPoints];
    values[index] = event.target.value;
    setHomeworkPoints(values);
  };

  const handleAddClick = () => {
    setHomeworkPoints([...homeworkPoints, ""]);
  };

  const handleRemoveClick = (index) => {
    const values = [...homeworkPoints];
    values.splice(index, 1);
    setHomeworkPoints(values);
  };

  const handleSubmit = async () => {
    if (
      !teacherSelectedClass ||
      !teacherSelectedSection ||
      !teacherSelectedSubject
    ) {
      setOpenDialog(true); // Show dialog if any required value is missing
      return;
    }

    const homeworkData = {
      className: teacherSelectedClass,
      section: teacherSelectedSection,
      date: teacherSelectedDate,
      homework: {
        subjectName: teacherSelectedSubject,
        works: homeworkPoints,
      },
    };

    const response = await fetch(addHomeworkUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(homeworkData),
    });

    if (response.ok) {
      alert("Homework added successfully");
      onSubmit();
    } else {
      alert("Error adding homework");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Add Homework
      </Typography>

      {homeworkPoints.map((point, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label={`Homework Point ${index + 1}`}
            variant="outlined"
            value={point}
            onChange={(event) => handleInputChange(index, event)}
            sx={{ marginRight: 2 }}
          />
          <IconButton
            color="primary"
            onClick={handleAddClick}
            aria-label="add"
            sx={{ marginRight: 1 }}
          >
            <AddIcon />
          </IconButton>
          {homeworkPoints.length > 1 && (
            <IconButton
              color="secondary"
              onClick={() => handleRemoveClick(index)}
              aria-label="remove"
            >
              <RemoveIcon />
            </IconButton>
          )}
        </Box>
      ))}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ marginTop: 3 }}
      >
        Submit Homework
      </Button>

      {/* Dialog for missing fields */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Incomplete Information</DialogTitle>
        <DialogContent>
          <Typography>
            Please select a class, section, and subject before submitting the
            homework.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddHomework;
