import React, { useState, useEffect, useMemo } from "react";
import baseUrl from "./Configs";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Collapse,
  Grid,
  Box,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
  teacherSelectedDateState,
  headerSelectorsState,
} from "./Atoms";

import "./Css/StudentTable2.css";
import "./Css/Basics.css";

import Header from "./Header";
import OverallChart from "./OverallChart";
import SubjectwiseChart from "./SubjectwiseChart";
import ShowStudentBio from "./ShowStudentBio";
import TestReportChart from "./TestReportChart";
import ShowRemarks from "./ShowRemarks";
import ShowAttendance from "./ShowAttendance";

const StudentTable2 = () => {
  const [classes, setClasses] = useState([]);
  // const [selectedClass, setSelectedClass] = useState("");
  // const [selectedSection, setSelectedSection] = useState("");
  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  const [students, setStudents] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("all");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTest, setSelectedTest] = useState("FA-1");
  const [selectedDate, setTeacherSelectedDate] = useRecoilState(
    teacherSelectedDateState
  );

  const getClassesUrl = `${baseUrl}/v1/classroute/getavailableclasses`;
  const getStudentsUrl = `${baseUrl}/v1/classroute/getstudentlist?className=${selectedClass}&section=${selectedSection}`;

  console.log(selectedStudent);

  const handleRowClick = (student) => {
    setSelectedStudent(student.rollNumber);
    setOpen(!open);
  };

  const fetchStudents = async () => {
    const response = await fetch(getStudentsUrl, optionsForGet);
    const data = await response.json();
    setStudents(data);
  };

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  function filterDataByTimePeriod(data, timePeriod) {
    let now = new Date();
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(now.getDate() - 7);
    let oneMonthAgo = new Date();
    oneMonthAgo.setMonth(now.getMonth() - 1);

    if (timePeriod === "week") {
      return data.filter((item) => new Date(item.date) >= oneWeekAgo);
    } else if (timePeriod === "month") {
      return data.filter((item) => new Date(item.date) >= oneMonthAgo);
    } else {
      return data;
    }
  }

  // Step 1: Calculate the average score for each student
  const studentsWithAverageScore = useMemo(() => {
    let studentsWithScore = students.map((student) => {
      let { averageClassScore, averageHomeworkScore } = student.averageScores;
      let count = 0;
      let total = 0;

      if (averageClassScore !== 0) {
        total += averageClassScore;
        count++;
      }

      if (averageHomeworkScore !== 0) {
        total += averageHomeworkScore;
        count++;
      }

      let averageScore = count !== 0 ? total / count : 0;

      return { ...student, averageScore };
    });

    // Step 2: Sort the students in descending order based on the average score
    let sortedAverageScores = [...studentsWithScore].sort(
      (a, b) => b.averageScore - a.averageScore
    );
    return studentsWithScore.map((student) => {
      let rank =
        sortedAverageScores.findIndex(
          (sortedStudent) => sortedStudent.rollNumber === student.rollNumber
        ) + 1;
      return { ...student, rank };
    });
  }, [students]);

  useEffect(() => {
    // fetchClasses();
    if (selectedClass && selectedSection) {
      fetchStudents();
    }
  }, [selectedSection, selectedClass]);

  useEffect(() => {
    setHeaderSelectors({
      classSelector: true,
      sectionSelector: true,
      subjectSelector: false,
      dateSelector: false,
      backMethod: null,
    });
  }, []);

  return (
    <div className="student-table-2_parent">
      <Header
        selectors={{
          classSelector: true,
          sectionSelector: true,
          subjectSelector: false,
          backButton: true,
          dateSelector: true,
        }}
      />

      <TableContainer className="tableContainer" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Roll Number</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Overall Rank</TableCell>
              <TableCell>Academic Rank</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentsWithAverageScore.map((student, index) => (
              <React.Fragment key={index}>
                <TableRow
                  onClick={() => handleRowClick(student)}
                  className={
                    selectedStudent === student.rollNumber && open
                      ? "sticky-row"
                      : ""
                  }
                >
                  <TableCell>{student.rollNumber}</TableCell>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>{student.overallRank}</TableCell>
                  <TableCell>{student.rank}</TableCell>
                  {console.log(student)}
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={open && selectedStudent === student.rollNumber}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <FormControl fullWidth>
                                  <InputLabel id="time-period-select-label">
                                    Select Time Period
                                  </InputLabel>
                                  <Select
                                    labelId="time-period-select-label"
                                    id="time-period-select"
                                    value={selectedTimePeriod}
                                    onChange={(e) =>
                                      setSelectedTimePeriod(e.target.value)
                                    }
                                  >
                                    <MenuItem value="all">All Time</MenuItem>
                                    <MenuItem value="week">This Week</MenuItem>
                                    <MenuItem value="month">
                                      This Month
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={6}
                              >
                                <Box margin={1}>
                                  <Table size="small" aria-label="purchases">
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>
                                          <Grid container spacing={2}>
                                            {/* OverallChart */}
                                            <Grid
                                              item
                                              xs={12}
                                              md={6}
                                              lg={4}
                                              sx={{ height: 500, width: 200 }}
                                            >
                                              <OverallChart
                                                data={filterDataByTimePeriod(
                                                  student.dailyReport,
                                                  selectedTimePeriod
                                                )}
                                              />
                                            </Grid>

                                            {/* SubjectwiseChart */}
                                            <Grid
                                              item
                                              xs={12}
                                              md={6}
                                              lg={4}
                                              sx={{ height: 500, width: 200 }}
                                            >
                                              <SubjectwiseChart
                                                data={filterDataByTimePeriod(
                                                  student.dailyReport,
                                                  selectedTimePeriod
                                                )}
                                              />
                                            </Grid>

                                            {/* Student Bio */}
                                            <Grid
                                              item
                                              xs={12}
                                              lg={4}
                                              sx={{ height: 500, width: 200 }}
                                            >
                                              <ShowStudentBio data={student} />
                                            </Grid>
                                          </Grid>

                                          <Grid container spacing={2}>
                                            {/* Test Report Chart */}
                                            {student.testReport.length > 0 && (
                                              <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                sx={{ height: 500, width: 200 }}
                                              >
                                                {student.testReport
                                                  .filter(
                                                    (report) =>
                                                      report.testName ===
                                                      selectedTest
                                                  )
                                                  .map((report, index) => (
                                                    <TestReportChart
                                                      key={index}
                                                      data={report}
                                                      selectedTest={
                                                        selectedTest
                                                      }
                                                      setSelectedTest={
                                                        setSelectedTest
                                                      }
                                                      testReport={
                                                        student.testReport
                                                      }
                                                    />
                                                  ))}
                                              </Grid>
                                            )}
                                            {/* Attendance Chart */}
                                            <Grid
                                              item
                                              xs={12}
                                              md={6}
                                              lg={4}
                                              sx={{ height: 500, width: 200 }}
                                            >
                                              <ShowAttendance
                                                attendanceData={
                                                  student.attendance
                                                }
                                              />
                                            </Grid>

                                            {/* Remarks */}
                                            <Grid
                                              item
                                              xs={12}
                                              lg={4}
                                              sx={{ height: 500, width: 200 }}
                                            >
                                              <ShowRemarks
                                                studentId={student._id}
                                              />
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StudentTable2;
