// EditStudentsTable.js
import React, { useState, useEffect } from "react";
import baseUrl from "./Configs";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Box,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
} from "./Atoms";

import Header from "./Header";
import EditExistingStudent from "./EditExistingStudent";
import { headerSelectorsState } from "./Atoms";
import "./Css/StudentTable2.css";
import "./Css/Basics.css";

const EditStudentsTable = (props) => {
  // Use Recoil states for selected class and section
  const [selectedClass] = useRecoilState(teacherSelectedClassState);
  const [selectedSection] = useRecoilState(teacherSelectedSectionState);
  const [students, setStudents] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  // const setHeaderSelectorsState = useSetRecoilState(headerSelectorsState);
  // Construct the URL for fetching students based on selected class and section
  const getStudentsUrl = `${baseUrl}/v1/classroute/getstudentlist?className=${selectedClass}&section=${selectedSection}`;

  // Options for the GET request
  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  // Fetch students from the server when the selected class or section changes
  const fetchStudents = async () => {
    if (selectedClass && selectedSection) {
      const response = await fetch(getStudentsUrl, optionsForGet);
      const data = await response.json();
      setStudents(data);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, [selectedClass, selectedSection]);

  // Toggle the collapse for the clicked student row.
  // If the same student is clicked again, collapse the row.
  const handleRowClick = (student) => {
    if (selectedStudent && selectedStudent === student._id) {
      setOpen(!open);
    } else {
      setSelectedStudent(student._id);
      setOpen(true);
    }
  };

  // Set header selectors to show class and section selectors
  useEffect(() => {
    setTimeout(() => {
      setHeaderSelectors({
        classSelector: true,
        sectionSelector: true,
        subjectSelector: false,
        dateSelector: false,
        backMethod: props.handleBackClick,
      });
    }, 10);
  }, []);

  return (
    <div className="student-table-2_parent">
      {/* <Header
        selectors={{
          classSelector: true,
          sectionSelector: true,
          backButton: true,
        }}
      /> */}
      <TableContainer className="tableContainer" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Roll Number</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student, index) => (
              <React.Fragment key={student._id || index}>
                <TableRow
                  onClick={() => handleRowClick(student)}
                  className={
                    selectedStudent === student._id && open ? "sticky-row" : ""
                  }
                >
                  <TableCell>{student.rollNumber}</TableCell>
                  <TableCell>{student.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={2}
                  >
                    <Collapse
                      in={open && selectedStudent === student._id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={1}>
                        {/* This is where the EditExistingStudent component is rendered */}
                        <EditExistingStudent student={student} />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EditStudentsTable;
