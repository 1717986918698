// Helper function: convert a string (with spaces or hyphens) to camelCase.
const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
};

// Convert camelCase to a string with spaces.
const toTitleCase = (str) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, (s) => s.toUpperCase());
};

export { toCamelCase, toTitleCase };
