// EditExistingStudent.js
import React, { useState, useEffect } from "react";
import baseUrl from "./Configs";
import BounceLoader from "react-spinners/ClipLoader";
import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const EditExistingStudent = ({ student, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  // originalData will hold the initial values (for comparison later)
  const [originalData, setOriginalData] = useState({});

  // Student Info state variables
  const [name, setName] = useState("");
  const [rollNumber, setRollNumber] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [admissionNumber, setAdmissionNumber] = useState("");
  const [previousSchool, setPreviousSchool] = useState("");
  const [busService, setBusService] = useState("");
  const [busNumber, setBusNumber] = useState("");
  const [busStop, setBusStop] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  // Parents info state – each object includes relation, name, email, etc.
  const [parentsInfo, setParentsInfo] = useState([
    {
      relation: "",
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      education: "",
      occupation: "",
    },
  ]);

  // For class selection – similar to AddStudent; you may need to allow changing class/section
  const [classes, setClasses] = useState([]);
  const getClassesUrl = `${baseUrl}/v1/classroute/getavailableclasses`;
  const optionsForClass = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const groupClasses = (classesData) => {
    return classesData.reduce((groupedClasses, cls) => {
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );
      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  useEffect(() => {
    // Fetch available classes (for the class/section selectors)
    fetch(getClassesUrl, optionsForClass)
      .then((response) => response.json())
      .then((data) => {
        setClasses(groupClasses(data));
      })
      .catch((error) => {
        console.error("Failed to fetch classes:", error);
        alert("Failed to fetch classes. Please try again.");
      });
  }, []);

  // Initialize form state when the student prop is available/changes.
  useEffect(() => {
    if (student) {
      setName(student.name || "");
      setRollNumber(student.rollNumber || "");
      setUsername(student.username || "");
      setPassword(""); // Leave password blank unless updating
      setAddress(student.address || "");
      setAdmissionNumber(student.admissionNumber || "");
      setPreviousSchool(student.previousSchool || "");
      setBusService(student.busService || "");
      setBusNumber(student.busNumber || "");
      setBusStop(student.busStop || "");
      setGender(student.gender || "");
      setEmail(student.email || "");
      setDateOfBirth(
        student.dateOfBirth ? new Date(student.dateOfBirth) : null
      );
      setPhoneNumber(student.phoneNumber || "");
      setSelectedClass(student.className || "");
      setSelectedSection(student.section || "");
      if (student.parents && student.parents.length > 0) {
        // Map the parent's info from the student.parents array (which contains nested parent details)
        const initialParents = student.parents.map((p) => ({
          relation: p.relation || "",
          name: p.parent?.name || "",
          email: p.parent?.email || "",
          phoneNumber: p.parent?.phoneNumber || "",
          address: p.parent?.address || "",
          education: p.parent?.education || "",
          occupation: p.parent?.occupation || "",
        }));
        setParentsInfo(initialParents);
      }
      // Save a copy of the original data (for diffing later)
      setOriginalData({
        name: student.name || "",
        rollNumber: student.rollNumber || "",
        username: student.username || "",
        address: student.address || "",
        admissionNumber: student.admissionNumber || "",
        previousSchool: student.previousSchool || "",
        busService: student.busService || "",
        busNumber: student.busNumber || "",
        busStop: student.busStop || "",
        gender: student.gender || "",
        email: student.email || "",
        dateOfBirth: student.dateOfBirth ? new Date(student.dateOfBirth) : null,
        phoneNumber: student.phoneNumber || "",
        className: student.className || "",
        section: student.section || "",
        parentsInfo:
          student.parents && student.parents.length > 0
            ? student.parents.map((p) => ({
                relation: p.relation || "",
                name: p.parent?.name || "",
                email: p.parent?.email || "",
                phoneNumber: p.parent?.phoneNumber || "",
                address: p.parent?.address || "",
                education: p.parent?.education || "",
                occupation: p.parent?.occupation || "",
              }))
            : [],
      });
    }
  }, [student]);

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    setSelectedSection(""); // Reset section when class changes
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleParentChange = (index, field, value) => {
    const updatedParents = [...parentsInfo];
    updatedParents[index][field] = value;
    setParentsInfo(updatedParents);
  };

  const addParent = () => {
    setParentsInfo([
      ...parentsInfo,
      {
        relation: "",
        name: "",
        email: "",
        phoneNumber: "",
        address: "",
        education: "",
        occupation: "",
      },
    ]);
  };

  const removeParent = (index) => {
    const updatedParents = parentsInfo.filter((_, i) => i !== index);
    setParentsInfo(updatedParents);
  };

  // Build updateData: only include fields that differ from originalData.
  const createUpdateData = () => {
    const updateData = {};
    if (name !== originalData.name) updateData.name = name;
    if (rollNumber !== originalData.rollNumber)
      updateData.rollNumber = rollNumber;
    if (selectedClass !== originalData.className)
      updateData.className = selectedClass;
    if (selectedSection !== originalData.section)
      updateData.section = selectedSection;
    if (address !== originalData.address) updateData.address = address;
    if (admissionNumber !== originalData.admissionNumber)
      updateData.admissionNumber = admissionNumber;
    if (previousSchool !== originalData.previousSchool)
      updateData.previousSchool = previousSchool;
    if (busService !== originalData.busService)
      updateData.busService = busService;
    if (busNumber !== originalData.busNumber) updateData.busNumber = busNumber;
    if (busStop !== originalData.busStop) updateData.busStop = busStop;
    if (gender !== originalData.gender) updateData.gender = gender;
    if (email !== originalData.email) updateData.email = email;
    if (phoneNumber !== originalData.phoneNumber)
      updateData.phoneNumber = phoneNumber;
    if (username !== originalData.username) updateData.username = username;
    // For password, include it only if the field is non-empty (meaning the user wishes to update it)
    if (password.trim() !== "") updateData.password = password;

    // For dateOfBirth, compare ISO strings (if both exist)
    if (dateOfBirth) {
      if (
        !originalData.dateOfBirth ||
        dateOfBirth.toISOString() !== originalData.dateOfBirth.toISOString()
      ) {
        updateData.dateOfBirth = dateOfBirth.toISOString();
      }
    }

    // For parentsInfo, do a simple JSON string comparison.
    if (
      JSON.stringify(parentsInfo) !== JSON.stringify(originalData.parentsInfo)
    ) {
      updateData.parentsInfo = parentsInfo;
    }

    return updateData;
  };

  const updateStudentUrl = `${baseUrl}/v1/studentroute/updatestudentinfo`;

  const updateStudent = async () => {
    const updateData = createUpdateData();
    // If nothing was updated, alert the user.
    if (Object.keys(updateData).length === 0) {
      alert("No changes detected.");
      return;
    }

    const payload = {
      studentId: student._id,
      updateData: updateData,
    };

    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };

    setIsLoading(true);
    try {
      const response = await fetch(updateStudentUrl, options);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      console.log("Update successful:", jsonData);
      if (onSubmit) onSubmit();
    } catch (e) {
      console.error("Failed to update student:", e);
      alert("Failed to update student. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3, position: "relative" }}>
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <BounceLoader color="#4A90E2" loading={isLoading} size={60} />
        </Box>
      )}
      <Typography variant="h4" align="center" gutterBottom>
        Edit Student Information
      </Typography>
      <Grid container spacing={3}>
        {/* Left Column – Student Information */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Student Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="class-select-label">Class</InputLabel>
                    <Select
                      labelId="class-select-label"
                      id="class-select"
                      value={selectedClass}
                      label="Class"
                      onChange={handleClassChange}
                    >
                      {classes.map((cls, index) => (
                        <MenuItem key={index} value={cls.className}>
                          {`Class ${cls.className}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="section-select-label">Section</InputLabel>
                    <Select
                      labelId="section-select-label"
                      id="section-select"
                      value={selectedSection}
                      label="Section"
                      onChange={handleSectionChange}
                      disabled={!selectedClass}
                    >
                      {selectedClass &&
                        classes
                          .find((cls) => cls.className === selectedClass)
                          ?.sectionsAndSubjects.map((sAs, index) => (
                            <MenuItem key={index} value={sAs.section}>
                              {sAs.section}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Roll Number"
                    variant="outlined"
                    fullWidth
                    value={rollNumber}
                    onChange={(e) => setRollNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Admission Number"
                    variant="outlined"
                    fullWidth
                    value={admissionNumber}
                    onChange={(e) => setAdmissionNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Previous School"
                    variant="outlined"
                    fullWidth
                    value={previousSchool}
                    onChange={(e) => setPreviousSchool(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bus Service"
                    variant="outlined"
                    fullWidth
                    value={busService}
                    onChange={(e) => setBusService(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bus Number"
                    variant="outlined"
                    fullWidth
                    value={busNumber}
                    onChange={(e) => setBusNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bus Stop"
                    variant="outlined"
                    fullWidth
                    value={busStop}
                    onChange={(e) => setBusStop(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box sx={{ mt: 2 }}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Student's Login Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Email/Username"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      variant="outlined"
                      type="password"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      helperText="Leave blank if not changing"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        {/* Right Column – Contact Information and Guardians */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date of Birth"
                      value={dateOfBirth}
                      onChange={(newValue) => setDateOfBirth(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Guardians Section */}
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Guardian's Information
            </Typography>
            {parentsInfo.map((parent, index) => (
              <Card key={index} sx={{ mb: 2 }}>
                <CardContent>
                  <Box sx={{ position: "relative" }}>
                    <IconButton
                      aria-label="remove guardian"
                      onClick={() => removeParent(index)}
                      disabled={parentsInfo.length === 1}
                      sx={{ position: "absolute", top: 8, right: 8 }}
                    >
                      <RemoveCircleOutlineIcon color="error" />
                    </IconButton>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                          <InputLabel id={`relation-label-${index}`}>
                            Relation
                          </InputLabel>
                          <Select
                            labelId={`relation-label-${index}`}
                            id={`relation-select-${index}`}
                            value={parent.relation}
                            label="Relation"
                            onChange={(e) =>
                              handleParentChange(
                                index,
                                "relation",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value="father">Father</MenuItem>
                            <MenuItem value="mother">Mother</MenuItem>
                            <MenuItem value="guardian">Guardian</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          required
                          value={parent.name}
                          onChange={(e) =>
                            handleParentChange(index, "name", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Email/Username"
                          variant="outlined"
                          fullWidth
                          value={parent.email}
                          onChange={(e) =>
                            handleParentChange(index, "email", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          value={parent.phoneNumber}
                          onChange={(e) =>
                            handleParentChange(
                              index,
                              "phoneNumber",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Address"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={2}
                          value={parent.address}
                          onChange={(e) =>
                            handleParentChange(index, "address", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Education"
                          variant="outlined"
                          fullWidth
                          value={parent.education}
                          onChange={(e) =>
                            handleParentChange(
                              index,
                              "education",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Occupation"
                          variant="outlined"
                          fullWidth
                          value={parent.occupation}
                          onChange={(e) =>
                            handleParentChange(
                              index,
                              "occupation",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              onClick={addParent}
            >
              Add Guardian
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Submit Button */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={updateStudent}
          disabled={isLoading}
        >
          Update Student
        </Button>
      </Box>
    </Box>
  );
};

export default EditExistingStudent;
