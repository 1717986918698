import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import baseUrl from "./Configs";
import Header from "./Header";
import { useSetRecoilState, useRecoilState } from "recoil";
import { headerSelectorsState } from "./Atoms";

const StaffBio = () => {
  const [staffList, setStaffList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [loading, setLoading] = useState(false);
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  const getAllStaffUrl = `${baseUrl}/v1/staffroute/getallstaff`;
  const getStaffInfoUrl = `${baseUrl}/v1/staffroute/getstaffinfo`;

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const isMobile = useMediaQuery("(max-width:600px)");
  const detailsSectionRef = useRef(null);

  const fetchStaffList = async () => {
    try {
      const response = await axios.get(getAllStaffUrl, optionsForGet);
      setStaffList(response.data);
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  useEffect(() => {
    fetchStaffList();
  }, []);

  const fetchStaffInfo = async (staffId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${getStaffInfoUrl}/${staffId}`,
        optionsForGet
      );
      setSelectedStaff(response.data);

      // Automatically scroll to details section on mobile
      if (isMobile && detailsSectionRef.current) {
        detailsSectionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } catch (error) {
      console.error("Error fetching staff info:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setHeaderSelectors({
      classSelector: false,
      sectionSelector: false,
      subjectSelector: false,
      dateSelector: false,
      backMethod: null,
    });
  }, []);

  return (
    <div>
      <Header
        selectors={{
          classSelector: false,
          sectionSelector: false,
          subjectSelector: false,
        }}
      />

      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
          {/* Staff List */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Staff List
            </Typography>
            <List>
              {staffList.map((staff) => (
                <ListItem key={staff.id} disablePadding>
                  <ListItemButton onClick={() => fetchStaffInfo(staff.id)}>
                    <ListItemText primary={staff.name} secondary={staff.role} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>

          {/* Staff Bio */}
          <Grid item xs={12} md={8} ref={detailsSectionRef}>
            {loading ? (
              <CircularProgress />
            ) : selectedStaff ? (
              <Card>
                <CardHeader
                  title={selectedStaff.name}
                  subheader={selectedStaff.role}
                />
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    <strong>Email:</strong> {selectedStaff.email}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Phone:</strong> {selectedStaff.phoneNumber}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Address:</strong> {selectedStaff.address}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Gender:</strong> {selectedStaff.gender}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Date of Birth:</strong>{" "}
                    {new Date(selectedStaff.dateOfBirth).toLocaleDateString()}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6">Education Details</Typography>
                  {selectedStaff.educationDetails.map((edu, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                      <Typography variant="body2">
                        <strong>Institution:</strong> {edu.institution}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Qualification:</strong> {edu.qualification}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Year of Passing:</strong> {edu.yearOfPassing}
                      </Typography>
                    </Box>
                  ))}
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6">Bank Details</Typography>
                  <Typography variant="body2">
                    <strong>Account Number:</strong>{" "}
                    {selectedStaff.bankAccountDetails.accountNumber}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Bank Name:</strong>{" "}
                    {selectedStaff.bankAccountDetails.bankName}
                  </Typography>
                  <Typography variant="body2">
                    <strong>IFSC Code:</strong>{" "}
                    {selectedStaff.bankAccountDetails.ifscCode}
                  </Typography>
                  <Typography variant="body2">
                    <strong>UPI ID:</strong>{" "}
                    {selectedStaff.bankAccountDetails.upiId}
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <Typography variant="body1">
                Select a staff member to view their bio.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default StaffBio;
