import React, { useState, useEffect } from "react";
import baseUrl from "../Configs";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { useRecoilValue } from "recoil";
import {
  teacherSelectedClassState,
  teacherSelectedSectionState,
} from "../Atoms";

import "./Css/CreateNewExam.css";
import "../Css/Basics.css";

const CreateNewExam = (props) => {
  const [examName, setExamName] = useState("");
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([{ subjectName: "", date: "" }]);
  const [dialogOpen, setDialogOpen] = useState(false); // For Dialog popup
  const [dialogMessage, setDialogMessage] = useState(""); // Error message to show in the dialog

  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);

  let createNewExamUrl = `${baseUrl}/v1/examroute/addnewexam`;
  const getClassesUrl = `${baseUrl}/v1/classroute/getavailableclasses`;

  let newExamDetails = {
    examName: examName,
    className: teacherSelectedClass,
    section: teacherSelectedSection,
    subjects: subjects,
  };

  const handleInputChange = (event) => {
    setExamName(event.target.value);
  };

  const handleSubmit = async () => {
    if (!teacherSelectedClass || !teacherSelectedSection || !examName) {
      setDialogMessage(
        "Please fill out all required fields: Class, Section, and Exam Name."
      );
      setDialogOpen(true); // Open the dialog if fields are missing
      return;
    }

    fetch(createNewExamUrl, optionsForCreateNewExam)
      .then((data) => data.text())
      .then((textData) => console.log(textData))
      .then(props.onSubmit())
      .catch((e) => console.log(e));
  };

  const handleSubjectChange = (index, event) => {
    const values = [...subjects];
    values[index] = { ...values[index], subjectName: event.target.value };
    setSubjects(values);
  };

  const handleDateChange = (index, date) => {
    const newSubjects = [...subjects];
    newSubjects[index].date = date;
    setSubjects(newSubjects);
  };

  const addMore = () => {
    setSubjects([...subjects, { subjectName: "", date: "" }]);
  };

  const removeSubject = (index) => {
    const newSubjects = [...subjects];
    newSubjects.splice(index, 1); // Remove the subject at the given index
    setSubjects(newSubjects);
  };

  let optionsForCreateNewExam = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(newExamDetails),
  };

  let optionsForClass = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const groupClasses = (classes) => {
    return classes.reduce((groupedClasses, cls) => {
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  useEffect(() => {
    fetch(getClassesUrl, optionsForClass)
      .then((response) => response.json())
      .then((data) => setClasses(groupClasses(data)))
      .catch((error) => console.error("Failed to fetch classes:", error));
  }, []);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div className="createNewExamSection">
      <div className="inputSection">
        <Box sx={{ width: "100%" }}>
          <TextField
            sx={{ width: "100%" }}
            label="Exam Name"
            variant="filled"
            onChange={handleInputChange}
          />
        </Box>
      </div>

      <div>
        {subjects.map((subject, index) => (
          <div key={index} className="inputSection">
            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
              <FormControl fullWidth>
                <InputLabel id="subject-select-label">Subject</InputLabel>
                <Select
                  labelId="subject-select-label"
                  id="subject-select"
                  value={subject.subjectName}
                  onChange={(e) => handleSubjectChange(index, e)}
                >
                  {teacherSelectedClass &&
                    teacherSelectedSection &&
                    classes
                      .filter(
                        (cls) => cls.className === teacherSelectedClass
                      )[0]
                      ?.sectionsAndSubjects?.filter(
                        (sAs) => sAs.section === teacherSelectedSection
                      )[0]
                      ?.subjects?.map((subject, idx) => (
                        <MenuItem key={idx} value={subject.subjectName}>
                          {subject.subjectName}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date"
                    value={subject.date}
                    onChange={(date) => handleDateChange(index, date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>

              <IconButton
                color="secondary"
                onClick={() => removeSubject(index)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </div>
        ))}
        <div className="orange-button" onClick={addMore}>
          Add More Subject
        </div>
      </div>

      <div className="addButton" onClick={() => handleSubmit()}>
        <p>Create Exam</p>
      </div>

      {/* Dialog for error message */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Missing Information"}
        </DialogTitle>
        <DialogContent>
          <p>{dialogMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateNewExam;
