import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Css/ShowAttendance.css";
import { flexbox } from "@mui/system";

const ShowAttendance = ({ attendanceData }) => {
  const totalDays = attendanceData.length;
  const presentDays = attendanceData.filter(
    (item) => item.status === "Present"
  ).length;
  const absentDays = totalDays - presentDays;

  // Create marked dates for the calendar
  const markedDates = useMemo(() => {
    const marks = {};
    attendanceData.forEach(({ date, status }) => {
      const localDate = new Date(date); // Convert to local Date
      const formattedDate = localDate.toLocaleDateString("en-CA"); // Format as YYYY-MM-DD
      marks[formattedDate] = status === "Present" ? "green" : "red";
    });
    return marks;
  }, [attendanceData]);

  const tileClassName = ({ date }) => {
    const formattedDate = date.toLocaleDateString("en-CA"); // Format as YYYY-MM-DD
    if (markedDates[formattedDate] === "green") return "present";
    if (markedDates[formattedDate] === "red") return "absent";
    return null;
  };

  return (
    <div className="table-component">
      <Box>
        <Typography variant="h6" gutterBottom>
          Attendance Summary
        </Typography>
        <Box display="flex" justifyContent="space-around">
          <Typography>Total Days: {totalDays}</Typography>
          <Typography>Present: {presentDays}</Typography>
          <Typography>Absent: {absentDays}</Typography>
        </Box>

        <Box>
          <Calendar tileClassName={tileClassName} />
        </Box>
        {/* <style jsx>{`
          .present {
            background-color: green;
            color: white;
            border-radius: 20%;
          }
          .absent {
            background-color: red;
            color: white;
            border-radius: 20%;
          }
        `}</style> */}
      </Box>
    </div>
  );
};

export default ShowAttendance;
