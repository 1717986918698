import React, { useState } from "react";
import baseUrl from "./Configs";

import "./Css/EntryPage.css";

import Register from "./Register";
import LoginForm from "./Login";
import RegisterHeader from "./RegisterHeader";
import Banner from "./Banner";

const EntryPage = () => {
  return (
    <div className="entrypage-container">
      <div className="entrypage-header">
        <RegisterHeader />
      </div>
      {/* <div className="entrypage-banner">
        <Banner />
      </div> */}
      <div className="entrypage-content">
        <div className="entrypage-register">
          <Register />
        </div>
        <div className="entrypage-login">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default EntryPage;
