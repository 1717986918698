import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilState } from "recoil";

import AddClass from "./AddClass";
// import CreateNewExam from "./CreateNewExam";
import AddStudent from "./AddStudent";
import EditStudentsTable from "./EditStudentsTable";
import EditExistingExam from "./EditExistingExam";
import AddNewTeacher from "./AddNewTeacher";
import EditExistingClass from "./EditExistingClass";
import RegisterHeader from "./RegisterHeader";
import AddNewSyllabus from "./AddNewSyllabus";
import AddNewStaff from "./AddNewStaff";
import AcademicSessions from "./AcademicSessions";

import Header from "./Header";
import { headerSelectorsState } from "./Atoms";
import "./Css/AdminTasks.css";

const AdminTasks = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  const navigate = useNavigate();

  const handleButtonClick = (option) => {
    setSelectedOption(option);
    if (option === "Add New Syllabus") {
      navigate("/add-new-syllabus");
    }
  };

  const handleBackClick = () => {
    console.log("Back clicked");
    setSelectedOption(null);
  };

  useEffect(() => {
    setHeaderSelectors({
      classSelector: false,
      sectionSelector: false,
      subjectSelector: false,
      dateSelector: false,
      backMethod: selectedOption ? handleBackClick : null,
    });
  }, [selectedOption]);

  return (
    <div>
      <Header />
      {(() => {
        switch (selectedOption) {
          case "Create New Class":
            return (
              <>
                <AddClass onSubmit={() => setSelectedOption(null)} />
              </>
            );
          case "Edit Existing Class":
            return (
              <>
                <EditExistingClass onSubmit={() => setSelectedOption(null)} />
              </>
            );
          case "Add New Student":
            return (
              <>
                <AddStudent onSubmit={() => setSelectedOption(null)} />
              </>
            );
          case "Edit Existing Students":
            return (
              <>
                <EditStudentsTable
                  onSubmit={() => setSelectedOption(null)}
                  handleBackClick={() => handleBackClick()}
                />
              </>
            );
          case "Add New Staff":
            return (
              <>
                <AddNewStaff onSubmit={() => setSelectedOption(null)} />
              </>
            );
          case "Add New Syllabus":
            return (
              <>
                <AddNewSyllabus
                  onSubmit={() => setSelectedOption(null)}
                  handleBackClick={() => handleBackClick()}
                />
              </>
            );
          case "Academic Sessions":
            return (
              <>
                <AcademicSessions onSubmit={() => setSelectedOption(null)} />
              </>
            );
          default:
            return (
              <div className="options-container">
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Create New Class")}
                >
                  Create New Class
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Edit Existing Class")}
                >
                  Edit Existing Class
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Add New Student")}
                >
                  Add New Student
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Edit Existing Students")}
                >
                  Edit Existing Students
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Add New Staff")}
                >
                  Add New Staff
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Add New Syllabus")}
                >
                  Add New / Edit Syllabus
                </div>
                <div
                  className="blue-button"
                  onClick={() => handleButtonClick("Academic Sessions")}
                >
                  Academic Sessions
                </div>
              </div>
            );
        }
      })()}
    </div>
  );
};

export default AdminTasks;
