import React, { useEffect, useState } from "react";
import baseUrl from "./Configs";
import {
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { headerSelectorsState } from "./Atoms";
import { useRecoilState } from "recoil";

const AddClass = (props) => {
  const [className, setClassName] = useState("");
  const [section, setSection] = useState("");
  const [subjects, setSubjects] = useState([
    { subjectName: "", teacherId: "" },
  ]);
  const [teachersList, setTeachersList] = useState([]);
  const classDetails = {
    className,
    section,
    subjects,
  };

  const addNewClassUrl = `${baseUrl}/v1/classroute/addnewclass`;
  const getAllTeachersUrl = `${baseUrl}/v1/staffroute/getallteachers`;

  const postOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(classDetails),
  };

  const getOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const addNewClass = async () => {
    // Validate if className is empty
    if (!className.trim()) {
      alert("Class name is required!");
      return; // Stop form submission
    }

    console.log(classDetails);
    try {
      const response = await fetch(addNewClassUrl, postOptions);
      const jsonData = await response.json();
      console.log(jsonData);
      props.onSubmit();
    } catch (e) {
      console.log(e);
    }
  };

  const addMore = () => {
    setSubjects([...subjects, { subjectName: "", teacherId: "" }]);
  };

  const removeSubject = (index) => {
    const updatedSubjects = subjects.filter((_, i) => i !== index);
    setSubjects(updatedSubjects);
  };

  const handleSubjectChange = (index, event) => {
    const values = [...subjects];
    values[index] = { ...values[index], subjectName: event.target.value };
    setSubjects(values);
  };

  const handleTeacherChange = (index, event) => {
    const values = [...subjects];
    values[index] = { ...values[index], teacherId: event.target.value };
    setSubjects(values);
  };

  useEffect(() => {
    fetch(getAllTeachersUrl, getOptions)
      .then((response) => response.json())
      .then((data) => setTeachersList(data))
      .catch((e) => console.log("Failed to fetch teachers list", e));
  }, []);

  return (
    <Card sx={{ maxWidth: 800, margin: "2rem auto", padding: "2rem" }}>
      <CardContent>
        <Typography variant="h5" align="center" gutterBottom>
          Create Class
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Class"
              variant="outlined"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
              fullWidth
              required // Mark the field as required
              error={!className.trim()} // Show error state if empty
              helperText={!className.trim() ? "Class name is required" : ""} // Show helper text if empty
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Section"
              variant="outlined"
              value={section}
              onChange={(e) => setSection(e.target.value)}
              fullWidth
            />
          </Grid>
          {subjects.map((subject, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={5}>
                <TextField
                  label={`Subject ${index + 1}`}
                  variant="outlined"
                  value={subject.subjectName}
                  onChange={(event) => handleSubjectChange(index, event)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id={`teacher-select-label-${index}`}>
                    Teacher
                  </InputLabel>
                  <Select
                    labelId={`teacher-select-label-${index}`}
                    id={`teacher-select-${index}`}
                    value={subject.teacherId}
                    label="Teacher"
                    onChange={(e) => handleTeacherChange(index, e)}
                  >
                    {teachersList.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        {teacher.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <IconButton
                  color="error"
                  onClick={() => removeSubject(index)}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={addMore}
              fullWidth
            >
              Add More Subject
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={addNewClass}
              fullWidth
            >
              Create Class
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AddClass;
