import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  List,
  ListItem,
  Button,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Select,
  Collapse,
  MenuItem,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { styled } from "@mui/system";
import baseUrl from "../Configs";
import { useRecoilValue } from "recoil";

import {
  teacherSelectedClassState,
  teacherSelectedSubjectState,
  teacherSelectedSectionState,
} from "../Atoms";
import Header from "../Header";

const IndentedList = styled(List)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const Topic = ({
  topic,
  handleStatusChange,
  handleSingleSave,
  changedStatuses,
  originalStatuses,
}) => {
  const [open, setOpen] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClick = () => setOpen(!open);

  const toggleDescription = () => setShowFullDescription(!showFullDescription);

  const isChanged = changedStatuses[topic._id] !== undefined;

  return (
    <div>
      <ListItem
        button
        onClick={handleClick}
        style={{ flexDirection: "column", alignItems: "flex-start" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flex: 1, marginBottom: "8px", minWidth: "200px" }}>
            <ListItemText
              primary={topic.title}
              secondary={
                <>
                  {showFullDescription
                    ? topic.description
                    : `${topic.description.slice(0, 100)}${
                        topic.description.length > 100 ? "..." : ""
                      }`}
                  {topic.description.length > 100 && (
                    <span
                      onClick={toggleDescription}
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        marginLeft: 4,
                        textDecoration: "underline",
                      }}
                    >
                      {showFullDescription ? "Show less" : "Show more"}
                    </span>
                  )}
                </>
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              minWidth: "150px",
            }}
          >
            <Select
              value={topic.status}
              onChange={(e) =>
                handleStatusChange(topic._id, e.target.value, originalStatuses)
              }
              style={{ minWidth: "120px", flex: "1" }}
            >
              <MenuItem value="upcoming">Upcoming</MenuItem>
              <MenuItem value="in-progress">In Progress</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
            </Select>
            {isMobile && (
              <IconButton
                onClick={() => handleSingleSave(topic._id)}
                edge="end"
                disabled={!isChanged}
                style={{
                  color: isChanged ? "blue" : "gray",
                }}
              >
                <SaveIcon />
              </IconButton>
            )}
          </div>
        </div>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <IndentedList>
          {topic.subtopics.map((subtopic) => (
            <Topic
              key={subtopic._id}
              topic={subtopic}
              handleStatusChange={handleStatusChange}
              handleSingleSave={handleSingleSave}
              changedStatuses={changedStatuses}
              originalStatuses={originalStatuses}
            />
          ))}
        </IndentedList>
      </Collapse>
    </div>
  );
};

const LiveSyllabus = () => {
  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);
  const [isSyllabusFound, setIsSyllabusFound] = useState(false);
  const [syllabus, setSyllabus] = useState({ topics: [] });
  const [changedStatuses, setChangedStatuses] = useState({});
  const [originalStatuses, setOriginalStatuses] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleSingleSave = async (topicId) => {
    try {
      const response = await axios.post(
        `${baseUrl}/v1/syllabusroute/updatelivestatus`,
        [{ id: topicId, status: changedStatuses[topicId] }],
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.syllabus) {
        setSnackbarMessage("Status Updated Successfully");
        setOpenSnackbar(true);
        setChangedStatuses((prev) => {
          const { [topicId]: _, ...rest } = prev;
          return rest;
        });
      }
    } catch (error) {
      setSnackbarMessage("Error Updating Status");
      setOpenSnackbar(true);
    }
  };

  const handleStatusChange = (topicId, newStatus, originalStatuses) => {
    const updateStatus = (topics) => {
      for (let topic of topics) {
        if (topic._id === topicId) {
          topic.status = newStatus;
          if (newStatus === originalStatuses[topicId]) {
            setChangedStatuses((prev) => {
              const { [topicId]: _, ...rest } = prev;
              return rest;
            });
          } else {
            setChangedStatuses((prev) => ({ ...prev, [topicId]: newStatus }));
          }
          return;
        }
        if (topic.subtopics) updateStatus(topic.subtopics);
      }
    };
    updateStatus(syllabus.topics);
    setSyllabus({ ...syllabus });
  };

  const handleSave = async () => {
    try {
      const changes = Object.entries(changedStatuses).map(([id, status]) => ({
        id,
        status,
      }));
      const response = await axios.post(
        `${baseUrl}/v1/syllabusroute/updatelivestatus`,
        changes,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.syllabus) {
        setSnackbarMessage("Syllabus Updated Successfully");
        setOpenSnackbar(true);
        setChangedStatuses({});
      }
    } catch (error) {
      setSnackbarMessage("Error Updating Syllabus");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (
      teacherSelectedClass &&
      teacherSelectedSection &&
      teacherSelectedSubject
    ) {
      const fetchSyllabus = async () => {
        try {
          const response = await axios.get(
            `${baseUrl}/v1/syllabusroute/getsyllabus`,
            {
              params: {
                className: teacherSelectedClass,
                subject: teacherSelectedSubject,
                section: teacherSelectedSection,
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          setSyllabus(response.data.syllabus);
          const originalStatuses = {};
          const populateOriginalStatuses = (topics) => {
            for (let topic of topics) {
              originalStatuses[topic._id] = topic.status;
              if (topic.subtopics) populateOriginalStatuses(topic.subtopics);
            }
          };
          populateOriginalStatuses(response.data.syllabus.topics);
          setOriginalStatuses(originalStatuses);
          setIsSyllabusFound(true);
        } catch (error) {
          console.error("Error fetching syllabus:", error);
        }
      };

      fetchSyllabus();
    }
  }, [teacherSelectedClass, teacherSelectedSection, teacherSelectedSubject]);

  return (
    <div>
      {/*      <Header selectors={{ classSelector: false, sectionSelector: false, subjectSelector: false }} />
       */}
      <h1>{syllabus.subject}</h1>
      <List>
        {syllabus.topics.map((topic) => (
          <Topic
            key={topic._id}
            topic={topic}
            handleStatusChange={handleStatusChange}
            handleSingleSave={handleSingleSave}
            changedStatuses={changedStatuses}
            originalStatuses={originalStatuses}
          />
        ))}
      </List>
      {!isMobile && (
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleSave}
          disabled={Object.keys(changedStatuses).length === 0}
          sx={{
            marginTop: "16px",
            backgroundColor:
              Object.keys(changedStatuses).length === 0 ? "gray" : "blue",
          }}
        >
          Save All
        </Button>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1, // Ensures it's above modal/dialog
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.includes("Error") ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default LiveSyllabus;
