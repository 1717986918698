import React, { useState } from "react";
import baseUrl from "../Configs";
import { CircularProgress } from "@mui/material";
import "./Css/DayRating.css";
import { useRecoilValue } from "recoil";
import {
  studentwiseDailyReportState,
  teacherSelectedClassState,
  teacherSelectedDateState,
  teacherSelectedSubjectState,
  teacherSelectedSectionState,
} from "../Atoms";

const SubmitDayRating = ({ onSubmit }) => {
  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedDate = useRecoilValue(teacherSelectedDateState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);
  const studentwiseDailyReport = useRecoilValue(studentwiseDailyReportState);

  const [isLoading, setIsLoading] = useState(false);

  const updateDailyReportUrl = `${baseUrl}/v1/studentroute/updatedailyreport`;

  // Build the report payload
  const classwiseReport = {
    className: teacherSelectedClass,
    section: teacherSelectedSection,
    date: teacherSelectedDate,
    subject: teacherSelectedSubject,
    report: studentwiseDailyReport,
  };

  // Options for the fetch request
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(classwiseReport),
  };

  // Function to update the daily report
  const updateDailyReport = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(updateDailyReportUrl, options);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      setIsLoading(false);
      // Call the onSubmit callback to reset values or navigate away
      onSubmit();
    } catch (error) {
      setIsLoading(false);
      alert("Failed to submit day rating: " + error.message);
    }
  };

  // Validate before submitting and then update the report
  const handleSubmit = () => {
    if (teacherSelectedSubject === "") {
      window.alert("Please select a subject");
      return;
    }
    if (studentwiseDailyReport.length === 0) {
      window.alert("Please rate the students or click back to go back");
      return;
    }
    updateDailyReport();
  };

  return (
    <button
      className="submit-day-rating"
      onClick={handleSubmit}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        "Submit Day Rating"
      )}
    </button>
  );
};

export default SubmitDayRating;
